import React from "react"
import { graphql, Link } from "gatsby"
import Masonry from "react-masonry-css"
import Img from "gatsby-image"

//components
import Layout from "../components/Layout"
import Section from "../components/Section"
import Phone from "../components/Phone"
import Mail from "../components/Mail"
import Social from "../components/Social"

import CatFr from "../components/Menu/CatFr"
import CatNl from "../components/Menu/CatNl"

const CategorieTemplate = ({
  data: {
    cat,
    lang: { nodes },
    com,
  },
}) => {
  console.log(com)

  const langCurrent = cat.node_locale

  let doc = ""
  if (langCurrent === "fr") {
    doc = "commercants"
  } else {
    doc = "handelaren"
  }

  const langSelect = [
    { locale: nodes[0].node_locale, path: "/commercants/" + nodes[0].slug },
    { locale: nodes[1].node_locale, path: "/handelaren/" + nodes[1].slug },
  ]

  const myColumn = {
    default: 4,
    1200: 3,
    980: 2,
    740: 2,
    500: 1,
  }

  return (
    <Layout langSelect={langSelect} langCurrent={langCurrent}>
      <Section>
        <div className="sidebar">
          <div className="block">
            <h2>Type </h2>
            {langCurrent === "fr" ? <CatFr /> : <CatNl />}
          </div>
          <div className="block">
            <Phone />
          </div>
          <div className="block">
            <Mail langCurrent={langCurrent} />
          </div>
          <div className="block">
            <Social langCurrent={langCurrent} />
          </div>
        </div>
        <div className="content">
          <Masonry
            breakpointCols={myColumn}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {com.nodes[0]
              ? com.nodes.map((item, index) => {
                  return (
                    <div key={index}>
                      <Link to={`/${langCurrent}/${doc}/${item.slug}`}>
                        <Img fluid={item.logo.fluid} alt={item.title} />
                        <h2>{item.title}</h2>

                        <p>{item.subtitle}</p>
                      </Link>
                    </div>
                  )
                })
              : langCurrent === "fr"
              ? "aucun résultat"
              : "geen resultaat"}
          </Masonry>
        </div>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query GetOneCategorie(
    $slug: String
    $lang: String
    $type: String
    $clang: String
  ) {
    cat: contentfulCategorie(id: { eq: $slug }) {
      title
      node_locale
      slug
      contentful_id
    }
    lang: allContentfulCategorie(filter: { contentful_id: { eq: $lang } }) {
      nodes {
        node_locale
        slug
      }
    }
    com: allContentfulCommercant(
      filter: { type: { eq: $type }, node_locale: { eq: $clang } }
    ) {
      nodes {
        title
        slug
        type
        subtitle
        logo {
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid_tracedSVG
          }
        }
      }
    }
  }
`
export default CategorieTemplate
